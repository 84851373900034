import http from "../../utils/http"
/**
 * รับข้อมูลยอดดุลพื้นฐาน
 */
export function balance(params) {
    return http({
        url: "/api/memberaccount/info",
        data: params,
        forceLogin: true
    })
}
/**
 * รับการกําหนดค่าการรับชําระเงิน
 */
export function withdrawConfig(params) {
    return http({
        url: "/api/memberwithdraw/config",
        data: params,
        forceLogin: true
    })
}
/**
 * รับรายละเอียดยอดคงเหลือ
 */
export function balanceDetail(params) {
    return http({
        url: "/api/memberaccount/page",
        data: params,
        forceLogin: true
    })
}

/**
 * รับข้อมูลการรับชําระเงิน
 */
export function withdrawInfo(params) {
	return http({
		url: "/api/memberwithdraw/info",
		data: params,
	})
}

/**
 * รับข้อมูลบัญชีธนาคาร
 */
export function accountInfo(params) {
	return http({
		url: "/api/memberbankaccount/defaultinfo",
		data: params,
	})
}

/**
 * ถอน
 */
export function withdraw(params) {
	return http({
		url: "/api/memberwithdraw/apply",
		data: params,
	})
}

/**
 * ประวัติการถอนเงิน
 */
export function withdrawList(params) {
	return http({
		url: "/api/memberwithdraw/page",
		data: params,
	})
}

/**
 * รายละเอียดการรับชําระเงิน
 */
export function withdrawDetail(params) {
	return http({
		url: "/api/memberwithdraw/detail",
		data: params,
	})
}

/**
 * รายการแพ็กเกจเติมเงิน
 */
export function rechargeList(params) {
	return http({
		url: "/memberrecharge/api/memberrecharge/page",
		data: params,
	})
}

/**
 * รายละเอียดแพ็กเกจเติมเงิน
 */
export function rechargeDetail(params) {
	return http({
		url: "/memberrecharge/api/memberrecharge/info",
		data: params,
	})
}

/**
 * ชาร์จ
 */
export function recharge(params) {
	return http({
		url: "/memberrecharge/api/ordercreate/create",
		data: params,
	})
}

/**
 * บันทึกการเติมเงิน
 */
export function rechargeOrder(params) {
	return http({
		url: "/memberrecharge/api/order/page",
		data: params,
	})
}